<script lang="ts">
  import { trackMute } from "../analytics";
  import { audioDatabase } from "../data/asset-database";
  import { stories } from "../data/stories";

  let muted = false;

  function toggleMuted() {
    muted = !muted;
    trackMute(muted);
    Object.values(audioDatabase).forEach((a) => a.mute(muted));
    stories.forEach((s) => (s.videoTag.muted = muted));

    audioDatabase.clickAudio.play();
  }
</script>

<button class:muted on:click={toggleMuted}>
  {#if muted}
    unmute
  {:else}
    mute
  {/if}
</button>

<style lang="scss">
  @import "../styles/utils.scss";

  button {
    @include interface-font;
    pointer-events: initial;
    background: transparent url("/assets/icons/mute.svg") no-repeat;
    background-position-x: right;
    background-position-y: rem(10);
    border: none;
    padding: rem(8) rem(18) rem(8) rem(12);
    margin: 0;
    cursor: pointer;
    position: fixed;
    top: rem(16);
    right: rem(103);
    z-index: 2;
    opacity: 1;
    text-shadow: 1px 1px 2px black;
    transition: opacity 0.3s linear;
    &:focus-visible {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
</style>
