<script lang="ts">
  import { t } from "../../utils/i18n";

  import TypewriterText from "../../components/TypewriterText.svelte";

  export let onDone: Function;
  let text1El: TypewriterText;
  let text2El: TypewriterText;

  export function start() {
    text1El.start();
  }

  function onTypeFirstDone() {
    setTimeout(() => {
      text2El.start();
    }, 800);
  }

  function onTypeSecondDone() {
    setTimeout(onDone, 400);
  }
</script>

<div>
  <TypewriterText
    bind:this={text1El}
    onDone={onTypeFirstDone}
    text={$t("intro.text1")}
  />
</div>
<div>
  <TypewriterText
    bind:this={text2El}
    onDone={onTypeSecondDone}
    text={"WTFFF!?"}
  />
</div>

<style lang="scss">
  div {
    margin: 30px auto;
  }
</style>
