export default {
    en: {
        "interface.share": "Share your story",
        "interface.close": "Close",
        "interface.story": "Story of",
        "interface.replay": "Replay",
        "interface.listened": "Listened",
        "interface.fullscreen": "Fullscreen",
        "intro.loading": "loading",
        "intro.story": " to a story",
        "intro.headphones": "Use your headphones for the best experience",
        "intro.text1": "5 young people were blackmailed, shamed and abused with nude photos",
        "intro.text2": "Check their stories",
        "intro.start": "Start!",
        "intro.share": "Write your story",
        "intro.or": "or",
        "intro.subtitles1": "Online sexual abuse or harassment. It happens to more and more people, including many young people.",
        "intro.subtitles2": "Do you want to know what it feels like to experience and overcome this? Swipe over the artwork and check out the stories",
        "video.play": "Play",
        "theme.fear": "Fear",
        "theme.guilt": "Guilt",
        "theme.what": "What is it?",
        "theme.support": "Support",
        "theme.help": "Need help?",
        "theme.sextortion": "Sextortion",
        "theme.shame": "Shame",
        "theme.help.button": "Need help?",
        "interface.help": "Need help?",
        "browser.unsupported": "Your browser is unfortunately not supported. Try opening wtfff.nl with Chrome, Safari or Edge. Or use this simple <a href='/simple.html'>overview of all the stories</a>.",
        "load.error": "Shit, something went wrong while loading WTFFF!?. Please try again or use this simple <a href='/simple.html'>overview of all the stories</a>.",
        "credits": "WTFFF!?",
        "simple.link": "/simple.html",
        "simple.label": "Stories overview",
        "simple.text": "WTFFF!? can be explored with your mouse, touchscreen or trackpad. If you don't want to or can't use one of those there is a simple overview of all the stories.",
    },
    nl: {
        "interface.share": "Deel je verhaal",
        "interface.close": "Sluiten",
        "interface.story": "Verhaal van",
        "interface.replay": "Opnieuw",
        "interface.listened": "Beluisterd",
        "interface.fullscreen": "Volledig scherm",
        "intro.loading": "laden",
        "intro.headphones": "Zet je hoofdtelefoon op voor de beste ervaring",
        "intro.text1": "5 jonge mensen werden gechanteerd, geshamed en misbruikt met naaktfoto’s",
        "intro.text2": "Check hun verhalen",
        "intro.start": "Start!",
        "intro.share": "Schrijf jouw verhaal",
        "intro.or": "of",
        "intro.subtitles1": "Online seksueel misbruik of seksuele intimidatie. Het overkomt steeds meer mensen, waaronder veel jongeren.",
        "intro.subtitles2": "Wil jij weten hoe het voelt om dit mee te maken en te overwinnen? Swipe dan over het artwork en check de verhalen",
        "video.play": "Afspelen",
        "theme.fear": "Angst",
        "theme.guilt": "Schuldgevoel",
        "theme.what": "Wat is het?",
        "theme.support": "Naasten",
        "theme.help": "Hulp nodig?",
        "theme.sextortion": "Sextortion",
        "theme.shame": "Schaamte",
        "theme.help.button": "Hulp nodig?",
        "interface.help": "Hulp nodig?",
        "browser.unsupported": "Je browser wordt helaas niet ondersteund. Probeer wtfff.nl te openen met Chrome, Safari of Edge. Of bekijk dit eenvoudige <a href='/simpel.html'>overzicht van alle verhalen</a>.",
        "load.error": "Shit, er is iets fout gegaan bij het laden van WTFFF!?. Probeer het nog eens of bekijk dit eenvoudige <a href='/simpel.html'>overzicht van alle verhalen</a>.",
        "credits": "WTFFF!?",
        "simple.link": "/simpel.html",
        "simple.label": "Verhalen overzicht",
        "simple.text": "WTFFF!? ontdek je met je muis, touchscreen of trackpad. Als je daar geen gebruik kan of wilt maken dan is er een eenvoudig overzicht van alle verhalen.",
    },
};
