<script lang="ts">
  import type { Story } from "../types/story";
  import {
    activeStory,
    clickedStory,
    toBecomeActiveStory,
    introDone,
  } from "../contexts/interactive-context";
  import { trackVideoTitle } from "../analytics";

  export let story: Story;

  function onClick() {
    clickedStory.set(story.id);
  }

  toBecomeActiveStory.subscribe((storyId) => {
    if (storyId == story.id) {
      trackVideoTitle(storyId);
    }
  });
</script>

<div
  class="story-title"
  class:visible={$toBecomeActiveStory == story.id && !$activeStory}
  aria-hidden={!$introDone}
  on:click={onClick}
>
  <h2>
    <span class="sr-only">{story.title}</span>
    {#each story.title as char, i}
      <span class="letter" style="transition-delay: {i * 50}ms;" aria-hidden="true"
        >{char}</span
      >
    {/each}
  </h2>
</div>

<style lang="scss">
  @import "../styles/utils.scss";
  .story-title {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;

    .letter {
      transition: opacity 0.25s, transform 0.25s;
      opacity: 0;
      display: inline-block;
      transform: translateY(60%);
    }

    &.visible {
      opacity: 1;
      pointer-events: initial;
      cursor: url("/assets/cursors/hand-pointer.webp"), pointer;

      .letter {
        opacity: 1;
        transform: translateY(0);
      }
    }

    h2 {
      @include header-font;
      white-space: nowrap;
      pointer-events: none;
      display: block;
      font-size: 7vw;
      line-height: 8.5vw;
      color: $foreground-color;
      margin: 0;
      padding: 0;
      text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);

      @media #{$small-screen} {
        font-size: 17vw;
        line-height: 21vw;
      }
    }
    .sr-only {
      position: absolute;
      z-index: -1;
      opacity: 0;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
</style>
