<script lang="ts">
  import {
    activeStory,
    introDone,
    storyEnded,
  } from "../contexts/interactive-context";
  import ShareButton from "./ShareButton.svelte";
  import HelpButton from "./HelpButton.svelte";
  import { fly } from "svelte/transition";

  $: show = !$activeStory && $introDone || $storyEnded;
</script>

{#if show}
  <div class:storyEnded={$storyEnded} transition:fly={{ y: 20, duration: 400 }}>
    <HelpButton />
    <ShareButton />
  </div>
{/if}

<style lang="scss">
  @import "../styles/utils.scss";
  div {
    @include interface-font;
    bottom: rem(16);
    right: $page-gutter;
    z-index: 2;
    display: flex;
    position: fixed;
    flex-direction: row;

    @media #{$small-screen} {
      right: $small-page-gutter;
      flex-direction: column;
      align-items: flex-end;

      &.storyEnded {
        bottom: rem(56);
      }
    }
  }
</style>
