<script lang="ts">
  import { trackReplay } from "../analytics";
  import { activeStory } from "../contexts/interactive-context";
  import { audioDatabase } from "../data/asset-database";
  import { t } from "../utils/i18n";
  import { playVideo } from "../utils/video";
  import { fly } from "svelte/transition";
  export let show = false;

  function onReplay() {
    trackReplay($activeStory);
    audioDatabase.clickAudio.play();
    playVideo($activeStory, 0).catch(console.warn);
  }
</script>

{#if show}
  <button on:click={onReplay} transition:fly|local={{ y: 20, duration: 400 }}>{$t("interface.replay")}</button>
{/if}

<style lang="scss">
  @import "../styles/utils.scss";

  button {
    @include rounded-button("/assets/icons/replay.svg");
    margin-top: rem(10);

    @media #{$small-screen} {
      position: absolute;
      margin: 0 rem(2);
      bottom: rem(50);
      left: rem($small-page-gutter);
    }
  }
</style>
