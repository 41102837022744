import { derived, writable } from "svelte/store";
import translations from "../data/translations";
export const languages = [
    { src: "nl", label: "NL" },
    { src: "en", label: "ENG" },
];
export const locale = writable(getBrowserLanguage());
function translate(locale, key) {
    // Let's throw some errors if we're trying to use keys/locales that don't exist.
    if (!key)
        throw new Error("no key provided to $t()");
    if (!locale)
        throw new Error(`no translation for key "${key}"`);
    // Grab the translation from the translations object.
    let text = translations[locale][key];
    if (!text)
        throw new Error(`no translation found for ${locale}.${key}`);
    return text;
}
export const t = derived(locale, ($locale) => (key) => {
    return translate($locale, key);
});
function getBrowserLanguage() {
    const browserLanguage = navigator.language.substring(0, 2).toLowerCase();
    const foundLanguage = languages.find((l) => l.src == browserLanguage);
    if (foundLanguage) {
        return foundLanguage.src;
    }
    else {
        return "en";
    }
}
