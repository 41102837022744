<script lang="ts">
  import {
    trackIntroButtonClick,
    trackIntroDone,
    trackIntroDragStart,
    trackIntroHeadphonesStart,
    trackIntroStart,
    trackIntroTextStart,
    trackShowIntroButton,
  } from "../../analytics";
  import {
    camera,
    dragIntroStarted,
    introDone,
    introStarted,
    minZoom,
    sceneHeight,
    sceneWidth,
    zoomManager,
  } from "../../contexts/interactive-context";
  import TranslatedContainer from "./TranslatedContainer.svelte";

  import { audioDatabase } from "../../data/asset-database";
  import { stories } from "../../data/stories";
  import { Vector2 } from "../../utils/vectors";

  import DragIntro from "./DragIntro.svelte";
  // import HeadphonesIntro from "./HeadphonesIntro.svelte";
  import TextIntro from "./TextIntro.svelte";
  import { noIntro } from "../../contexts/debug-context";
  import { isSafari } from "../../utils/device";
  import { t } from "../../utils/i18n";
  import { fade } from "svelte/transition";

  // let headphonesIntro: HeadphonesIntro;
  let dragIntro: DragIntro;
  let textIntro: TextIntro;
  let buttonContainer: TranslatedContainer;
  let subtitles;
  let subtitlesVisible = false;
  let startButtonDisabled = true;
  let textIntroAndButtonVisible = true;
  let simpleLinkVisible = false;

  export function startIntro() {
    trackIntroStart();
    $camera.goToPosition(
      new Vector2(sceneWidth / 2, sceneHeight / 2),
      0,
      "easeLinear"
    );

    if ($noIntro) {
      setTimeout(() => onClick(), 100);
    } else {
      setTimeout(() => {
        trackIntroTextStart();
        textIntro.start();
      }, 200);
    }
  }

  // const onHeadPhonesDone = () => {
  //   setTimeout(() => {
  //     trackIntroTextStart();
  //     textIntro.start();
  //   }, 500);
  // };

  const onDragDone = () => {
    trackIntroDone();
    introDone.set(true);
  };

  const onTextDone = () => {
    trackShowIntroButton();
    buttonContainer.start();
    startButtonDisabled = false;
  };

  function onClick() {
    trackIntroButtonClick();
    audioDatabase.clickAudio.play();
    audioDatabase.backgroundAudio.play();
    textIntroAndButtonVisible = false;

    if (isSafari()) {
      // necessary to make it work on Safari / Firefox
      for (const story of stories) {
        story.videoTag.play().catch(() => {});
        story.videoTag.pause();
      }
    }

    const introZoomDuration = $noIntro ? 0.5 : 13;

    if (!$noIntro) {
      setTimeout(() => {
        subtitles = $t("intro.subtitles1");
        subtitlesVisible = true;
        audioDatabase.introAudio.on("play", () => {
          setTimeout(() => {
            subtitles = $t("intro.subtitles2");
          }, 6500);
        });

        audioDatabase.introAudio.on("end", () => {
          setTimeout(() => (subtitlesVisible = false), 0);
        });

        audioDatabase.introAudio.play();
      }, 2500);
    }

    $zoomManager.zoomFromTo(
      $minZoom,
      $zoomManager.calculateReliefZoomLevel(),
      introZoomDuration,
      "easeInOutSine",
      () => {
        if ($noIntro) {
          onDragDone();
        } else {
          trackIntroDragStart();
          dragIntro.start();
        }
      }
    );

    $camera.goToPosition(
      new Vector2(sceneWidth / 2 - 5, sceneHeight / 2 + 68),
      introZoomDuration,
      "easeInOutSine"
    );

    backgroundTransparent = true;
    introStarted.set(true);
  }

  $: backgroundTransparent = false;
</script>

<div
  class="container"
  class:transparent={backgroundTransparent}
  class:allowevents={$dragIntroStarted}
>
  <!-- <div class="centered narrow">
    <HeadphonesIntro bind:this={headphonesIntro} onDone={onHeadPhonesDone} />
  </div> -->
  <div class="centered">
    <DragIntro bind:this={dragIntro} onDone={onDragDone} />
  </div>
  {#if textIntroAndButtonVisible}
    <div class="centered narrow">
      <TextIntro bind:this={textIntro} onDone={onTextDone} />

      <TranslatedContainer bind:this={buttonContainer}>
        <button class="startbutton" on:click={onClick} disabled={startButtonDisabled}
          >{$t("intro.text2")}</button
        >
        <div class="ortext">{$t("intro.or")}</div>
        <a class="sharebutton" href="https://verhalen.wtfff.nl/" disabled={startButtonDisabled}
          >{$t("intro.share")}</a>
      </TranslatedContainer>
    </div>
  {/if}

  {#if subtitlesVisible}
    <div class="subtitles-container" transition:fade>
      <div class="subtitles">
        {subtitles}
      </div>
    </div>
  {:else}
    <div
      class="simple-link"
      class:visible={simpleLinkVisible}
      on:click={() => {
        simpleLinkVisible = !simpleLinkVisible;
      }}
    >
      <p>{$t("simple.text")}</p>
      <a href={$t("simple.link")} class:simpleLinkVisible
        >{$t("simple.label")}</a
      >
    </div>
  {/if}
</div>

<style lang="scss">
  @import "../../styles/utils.scss";
  .container {
    background: black;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    transition: background-color 1.5s ease-out;
    @include interface-font;

    .subtitles-container {
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      position: absolute;
      display: flex;
      align-content: center;
      justify-content: center;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 20%,
        rgba(0, 0, 0, 0) 100%
      );
      padding: 150px $page-gutter $page-gutter $page-gutter;

      @media #{$small-screen} {
        padding: 150px $small-page-gutter $small-page-gutter $small-page-gutter;
      }

      .subtitles {
        @include subtitle-font;
      }
    }

    &.transparent {
      background-color: transparent;
    }

    &.allowevents {
      pointer-events: none;
    }

    .centered {
      position: absolute;

      &.narrow {
        max-width: rem(250);
        text-align: center;
      }
    }

    .startbutton {      
      @include solid-rounded-button();
      padding: 0.8rem 1rem;
    }

    .ortext {
      margin: 15px 0;
    }

    .sharebutton {      
      @include rounded-button();
      display: inline-block;
      padding: 0.8rem 1rem;
    }

    .simple-link {
      display: none;
      @media #{$small-screen} {
        opacity: 0;
        text-align: center;
        position: absolute;
        bottom: 0;
        max-width: 100vw;
        @include interface-font;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: rem(10) rem(20) rem(20) rem(20);
        transition: opacity 0.3s linear;
        &.visible {
          opacity: 1;
        }
        a {
          visibility: hidden;
          display: block;
          @include interface-font;
          padding-bottom: rem(6);
          text-decoration: underline;
          text-underline-offset: 3px;
          transition: visibility 0.3s linear;
          &.simpleLinkVisible {
            visibility: visible;
          }
        }
      }
    }
  }
</style>
