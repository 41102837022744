<script lang="ts">
  let visible = false;
  export let text: string;
  export let delay = 0;
  export let onDone: Function | undefined = undefined;
  let done = false;

  export function start() {
    visible = true;
  }

  function typewriter(node, { speed = 30 }) {
    if (!visible) {
      return;
    }

    const duration = done ? 0 : node.childNodes.length * speed;
    let lastVisibleIndex = 0;
    return {
      duration,
      delay,
      tick: (t) => {
        const visibleIndex = Math.trunc(node.childNodes.length * t);
        for (let i = lastVisibleIndex; i < visibleIndex; i++) {
          node.childNodes[i].className = "visible";
        }
        lastVisibleIndex = visibleIndex;
      },
    };
  }
</script>

{#key text}
  <div>
    <p class="sr-only">{text}</p>
    {#key visible}
      <p
        in:typewriter
        on:introend={() => {
          if (!done && onDone && visible) {
            done = true;
            onDone();
          }
        }}
      >
        {#each text as char}
          <span aria-hidden="true">{char}</span>
        {/each}
      </p>
    {/key}
  </div>
{/key}

<style lang="scss" scoped>
  @import "../styles/utils.scss";
  div {
    position: relative;
  }
  p {
    white-space: pre-line;
  }
  span {
    visibility: hidden;
  }
  span.visible {
    visibility: visible;
  }
  .sr-only {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
</style>
