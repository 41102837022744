<script lang="ts">
  import { activeStory } from "../contexts/interactive-context";
  import { panelContent } from "../contexts/panel-context";

  import { audioDatabase } from "../data/asset-database";

  import { stories } from "../data/stories";
  import { isTouchDevice } from "../utils/touch";
  import { playVideo } from "../utils/video";

  let activeVideo = null;
  activeStory.subscribe((val) => (activeVideo = val));

  if (isTouchDevice()) {
    window.addEventListener("focus", function () {
      audioDatabase.backgroundAudio.play();
      if (!$panelContent) {
        playVideo(activeVideo).catch(console.warn);
      }
    });

    window.addEventListener("blur", function () {
      audioDatabase.backgroundAudio.pause();
      stories.forEach((s) => s.videoTag.pause());
    });
  }
</script>
