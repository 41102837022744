<script lang="ts">
  import TranslatedContainer from "./TranslatedContainer.svelte";
  import { dragIntroStarted } from "../../contexts/interactive-context";

  export let onDone: Function | undefined;
  let containerEl: TranslatedContainer;
  let visible = false;
  const timeouts = [];

  function onDragStart(ev: MouseEvent | TouchEvent) {
    if (visible) {
      timeouts.forEach(clearTimeout);
      visible = false;
      setTimeout(onDone, 200);
    }
  }

  export function start() {
    dragIntroStarted.set(true);
    visible = true;
    containerEl.start();
    timeouts.push(setTimeout(startHandAnimation, 1000));
  }

  function startHandAnimation() {
    animateHand = true;
    timeouts.push(setTimeout(endHandAnimation, 3300));
  }

  function endHandAnimation() {
    animateHand = false;
    timeouts.push(setTimeout(dragIntroDone, 1000));
  }

  function dragIntroDone() {
    containerEl.stop();
    visible = false;
    timeouts.push(setTimeout(onDone, 400)); // duration of animation of TranslatedContainer
  }

  $: animateHand = false;
</script>

<svelte:window
  on:mousedown|passive={onDragStart}
  on:touchstart|passive={onDragStart}
/>

<div class="container" class:visible>
  <TranslatedContainer bind:this={containerEl}>
    <div class="container-flex">
      <div class="hand-container" class:animateHand>
        <picture>
          <source
            srcset="/assets/sprites/intro/hand-open.webp"
            type="image/webp"
          />
          <img
            src="/assets/sprites/intro/hand-open.png"
            alt="hand open"
            class="hand-5"
            role="presentation"
          />
        </picture>
        <picture>
          <source
            srcset="/assets/sprites/intro/hand-closed.webp"
            type="image/webp"
          />
          <img
            src="/assets/sprites/intro/hand-closed.png"
            alt="hand closed"
            class="hand-0"
            role="presentation"
          />
        </picture>
      </div>
    </div>
  </TranslatedContainer>
</div>

<style lang="scss">
  @import "../../styles/utils.scss";
  .container {
    transition: opacity 0.2s ease-in;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }

  .container-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hand-container {
    position: relative;
    width: 120px;
    height: 120px;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: contain;

      &.hand-0 {
        visibility: hidden;
      }
    }

    &.animateHand {
      .hand-0 {
        visibility: visible;
        animation: moveLeftRight 3s ease;
        animation-delay: 0.3s;
      }

      .hand-5 {
        visibility: hidden;
      }
    }

    @keyframes moveLeftRight {
      from {
        transform: translateX(0);
      }

      33.33% {
        transform: translateX(-50%);
      }

      66.67% {
        transform: translateX(+50%);
      }

      to {
        transform: translateX(0);
      }
    }
  }
</style>
