<script lang="ts">
  import { panelContent } from "../contexts/panel-context";
  import { activeStory, introDone, smallScreen, storyEnded } from "../contexts/interactive-context";
  import { audioDatabase } from "../data/asset-database";
  import { trackCredits } from "../analytics";
  import { t } from "../utils/i18n";
  import { fly } from "svelte/transition";

  $: show = !$activeStory && $introDone || ($storyEnded && !$smallScreen);

  const CONTENT_FOLDER = "/assets/panels/";

  function showInfo() {
    trackCredits();
    audioDatabase.clickAudio.play();
    panelContent.set(`${CONTENT_FOLDER}credits`);
  }
</script>

{#if show}
<button on:click={showInfo} transition:fly={{ y: 20, duration: 400 }}>{$t("credits")}</button>
{/if}
<style lang="scss">
  @import "../styles/utils.scss";

  button {
    @include rounded-button;
    position: fixed;
    bottom: rem(16);
    left: $page-gutter;
    z-index: 2;
    display: block;
    border: 1px solid transparent;
    background-color: transparent;
    
    &:hover,
    &:focus {
      border: 1px solid $foreground-color;
      background-color: rgba(0, 0, 0, 0.6);
    }
    @media #{$small-screen} {
      left: $small-page-gutter;
    }
  }
</style>
