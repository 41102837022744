import { browser } from "./utils/device";
const percentagesToTrack = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].reverse();
let trackedVideoProgress = {};
let playedVideos = new Set();
export function trackVideoEvent(story, action) {
    const time = Math.round(story.videoTag.currentTime);
    const progress = Math.round(time / story.videoTag.duration * 100);
    if (action == "progress") {
        const trackingPercentage = percentagesToTrack.find((p) => progress >= p);
        const alreadyTrackedPercentage = trackedVideoProgress[story.id] || 0;
        if (trackingPercentage === undefined || trackingPercentage <= alreadyTrackedPercentage) {
            return;
        }
        else {
            trackedVideoProgress[story.id] = trackingPercentage;
        }
    }
    if (action == "play") {
        if (playedVideos.has(story.id)) {
            action = "resume";
        }
        else {
            playedVideos.add(story.id);
        }
    }
    gtag('event', `video_${action}`, {
        story: story.id,
        time,
        progress
    });
}
export function trackVideoStart(story) {
    gtag('event', 'video_start_button', {
        story,
    });
}
export function trackVideoTitle(story) {
    gtag('event', "video_title", { story });
}
export function trackUnsupported() {
    gtag('event', "app_unsupported", { browser: browser === null || browser === void 0 ? void 0 : browser.name, os: browser === null || browser === void 0 ? void 0 : browser.os });
}
export function trackLoadError() {
    gtag('event', "load_error");
}
export function trackAppStart() {
    gtag('event', "app_start");
}
export function trackIntroDone() {
    gtag('event', "intro_done");
}
export function trackIntroDragStart() {
    gtag('event', "intro_drag_start");
}
export function trackIntroTextStart() {
    gtag('event', "intro_text_start");
}
export function trackShowIntroButton() {
    gtag('event', "intro_button_show");
}
export function trackIntroHeadphonesStart() {
    gtag('event', "intro_headphone_start");
}
export function trackIntroStart() {
    gtag('event', "intro_start");
}
export function trackIntroButtonClick() {
    gtag('event', "intro_button_click");
}
export function trackOpenThemeInfo(story, theme) {
    gtag('event', "open_theme_info", { story, theme });
}
export function trackPlayTheme(story, theme) {
    gtag('event', "play_theme", { story, theme });
}
export function trackSwitchLanguage(language) {
    gtag('event', `switch_language_${language}`);
}
export function trackShareStory(story) {
    gtag('event', "share_story", { story });
}
export function trackMute(muted) {
    gtag('event', "mute", { muted });
}
export function trackFullscreen(fullscreen) {
    gtag('event', `fullscreen_${fullscreen}`);
}
export function trackCredits() {
    gtag('event', "credits");
}
export function trackReplay(story) {
    gtag('event', "replay", { story });
}
