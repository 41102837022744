<script lang="ts">
  import { locale, languages } from "../utils/i18n";
  import { onMount } from "svelte";
  import { audioDatabase } from "../data/asset-database";
  import { trackSwitchLanguage } from "../analytics";

  let selected = $locale;

  function onLanguageChange() {
    audioDatabase.clickAudio.play();
    storeLanguage();
    trackSwitchLanguage(selected);
  }

  function storeLanguage() {
    locale.set(selected);
  }

  onMount(storeLanguage);
</script>

<div class="lang-select">
  {#each languages as language}
    <input
      type="radio"
      id={`toggle-${language.label}`}
      bind:group={selected}
      value={language.src}
      on:change={() => onLanguageChange()}
    />
    <label for={`toggle-${language.label}`}>{language.label}</label>
  {/each}
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  .lang-select {
    position: fixed;
    top: rem(12);
    right: rem(16);
    z-index: 2;
    pointer-events: initial;
    display: flex;
    input[type="radio"] {
      position: absolute !important;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      width: 1px;
    }
    label {
      @include interface-font;
      padding: rem(12) rem(5);
      opacity: 0.5;
      cursor: pointer;
      text-shadow: 1px 1px 2px black;
    }
    input[type="radio"]:checked + label {
      opacity: 1;
    }
    input[type="radio"]:focus-visible + label {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
</style>
