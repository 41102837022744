<script type="ts">
  import { t } from "../utils/i18n";
  import { clickedTheme, panelContent } from "../contexts/panel-context";
  import { activeStory, camera } from "../contexts/interactive-context";
  import { storiesDictionary } from "../data/stories";
  import { locale } from "../utils/i18n";
  import { audioDatabase } from "../data/asset-database";
  import LoaderText from "./LoaderText.svelte";
  import { playVideo } from "../utils/video";
  import { trackPlayTheme } from "../analytics";

  panelContent.subscribe(toggleContent);
  locale.subscribe(onLanguageChanged);

  let transforming = false;
  let htmlContent;
  let showHelpButton = false;
  let panel;

  function onLanguageChanged(lang: {} | null) {
    if (!lang) {
      return;
    }
    toggleContent($panelContent);
  }

  function handleClickOutside(e) {
    if (!panel.contains(e.target)) {
      close();
    }
  }

  function toggleContent(content) {
    if (content) {
      $camera.stop();
      transforming = true;
      htmlContent = undefined;
      showHelpButton =
        !content.endsWith("theme_help") && !content.endsWith("credits");
      let info = `${content}_${$locale}.html`;

      if (!!$activeStory) {
        const tag = storiesDictionary[$activeStory]
          .videoTag as HTMLVideoElement;
        tag.pause();
      }

      document.addEventListener("click", handleClickOutside, true);

      fetch(info)
        .then((response) => response.text())
        .then((html) => (htmlContent = html));
    }
  }

  function playTheme() {
    const theme = $clickedTheme;
    trackPlayTheme($activeStory, theme.title);
    closePanel();
    setTimeout(
      () => playVideo($activeStory, theme.start).catch(console.warn),
      500
    );
  }

  function closePanel() {
    setTimeout(() => (transforming = false), 500);
    audioDatabase.clickAudio.play();
    panelContent.set(undefined);
    clickedTheme.set(undefined);
    document.removeEventListener("click", handleClickOutside, true);
    $camera.resume();
  }

  function close() {
    closePanel();
    playVideo($activeStory).catch(console.warn);
  }

  function help() {
    toggleContent("/assets/panels/theme_help");
  }
</script>

<div class="panelbackground {$panelContent ? 'show' : ''}" />
<div
  class="panel {$panelContent ? 'show' : ''} {transforming
    ? 'transforming'
    : ''}"
  bind:this={panel}
>
  <div class="ui">
    {#if $clickedTheme}
      <button on:click={playTheme} class="theme"
        >{$t($clickedTheme.title)}</button
      >
    {/if}

    <button on:click={close} class="close" aria-label={$t("interface.close")}
      >{$t("interface.close")}</button
    >
  </div>
  <div class="scroller">
    {#if !htmlContent}
      <div style="color: black !important;">
        <LoaderText text={$t("intro.loading")} color="black" />
      </div>
    {:else}
      <div class="html-content">
        {@html htmlContent}
      </div>

      {#if showHelpButton}
        <div class="footer-buttons">
          <button on:click={help} class="help-button"
            >{$t("theme.help.button")}</button
          >
          <a class="share-button" href="https://verhalen.wtfff.nl" target="_blank">{$t("interface.share")}</a>
        </div>
      {/if}
    {/if}
  </div>
</div>

<style lang="scss">
  @import "../styles/utils.scss";
  .panelbackground {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    width: 100%;
    z-index: 3;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;

    &.show {
      opacity: 0.5;
    }
  }

  .panel {
    pointer-events: initial;
    position: fixed;
    left: 0;
    top: 0;
    overflow: hidden;
    bottom: 0;
    z-index: 4;
    width: 50%;
    max-width: rem(800);
    min-width: rem(720);
    background-color: $background-color;
    transform: translateX(-100%);
    padding: $page-gutter;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 720px) {
      width: 100%;
      min-width: 100%;
    }

    @media #{$small-screen} {
      right: 0;
      width: 100%;
      padding: 0 $small-page-gutter;
      transform: translateX(0) translateY(100%);
      &.show {
        transform: translateY(0);
      }
    }

    &.transforming {
      transition: transform 0.4s ease-out;
    }

    &.show {
      transform: translateX(0);
    }

    .ui {
      top: 0;
      left: 0;
      right: 0;
      height: 125px;
      position: absolute;
      z-index: 2;
      @media #{$small-screen} {
        height: 100px;
      }
    }

    .help-button {
      @include solid-rounded-button($background: black, $foreground: $background-color);
    }

    .share-button {
      @include solid-rounded-button("/assets/icons/arrow-top-right-pink.svg", black, $background-color);

      margin: 10px auto;
    }

    .footer-buttons {
      margin: 30px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .theme {
      @include solid-rounded-button("/assets/icons/replay_panel.svg", black, $background-color);

      position: fixed;
      top: $page-gutter;
      left: 50%;
      transform: translateX(-50%);

      @media #{$small-screen} {
        top: $small-page-gutter;
        left: $small-page-gutter;
        transform: translateX(0);
      }
    }

    .close {
      cursor: pointer;
      background: #000 url("/assets/icons/close.svg") center center no-repeat;
      padding: 0;
      margin: 0;
      position: fixed;
      top: $page-gutter;
      right: $page-gutter;
      width: rem(36);
      height: rem(36);
      text-indent: -9999rem;
      border: 1px solid black;
      border-radius: rem(36);
      z-index: 2;
      @media #{$small-screen} {
        top: $small-page-gutter;
        right: $small-page-gutter;
      }
    }
    .scroller {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 100px $page-gutter $page-gutter $page-gutter;
      overflow-y: auto;
      
      @media #{$small-screen} {
        padding: 60px $small-page-gutter $small-page-gutter $small-page-gutter;
      }
    }
    .html-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .html-content :global(h2),
  .html-content :global(h1) {
    @include header-font;
    font-size: rem(44);
    line-height: rem(44);
    text-align: center;
    color: black;

    @media #{$small-screen} {
      font-size: rem(30);
      line-height: rem(28);
    }
  }

  .html-content :global(h1),
  .html-content :global(h2),
  .html-content :global(h3),
  .html-content :global(h4),
  .html-content :global(h5) {
    max-width: 80%;
    margin: rem(50) 0 rem(10);
    @media #{$small-screen} {
      max-width: none;
    }
  }

  .html-content :global(h1) {
    margin: rem(20) 0;
  }

  .html-content :global(h3) {
    @include header-font;
    font-size: rem(30);
    line-height: rem(28);
    text-align: center;
    color: black;

    @media #{$small-screen} {
      font-size: rem(26);
      line-height: rem(24);
    }
  }

  .html-content :global(h5) {
    @include interface-font;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    color: black;
    margin: rem(20);
  }

  .html-content :global(p),
  .html-content :global(.credits) {
    font-size: rem(16);
    color: black;
    line-height: rem(22);
    font-family: "GarnettRegular", Arial, Helvetica, sans-serif;
    align-self: flex-start;
    max-width: 70%;
    align-self: center;
    @media #{$small-screen} {
      max-width: none;
    }
  }

  .html-content :global(.credits p) {
    max-width: none;
  }

  .html-content :global(.creditslist) {
    margin: 40px 0;
  }

  .html-content :global(.credits li) {
    padding: 4px 0;
  }

  .html-content :global(.credits ul) {
    margin: 10px;
    padding-left: 10px;
  }

  .html-content :global(.credits strong) {
    font-family: "GarnettBlack", Arial, Helvetica, sans-serif;
  }

  .html-content :global(.right) {
    align-self: flex-end;

    @media #{$small-screen} {
      align-self: flex-start;
    }
  }

  .html-content :global(.balloon) {
    align-self: flex-end;
    box-shadow: 0 5px 5px -5px rgb(0 0 0 / 60%);
    margin: $small-page-gutter $page-gutter;
    background-color: $balloon-right-color;
    border-radius: rem(16);
    padding: rem(15);
    position: relative;
    max-width: 60%;
    @media #{$small-screen} {
      max-width: 80%;
    }
  }

  .html-content :global(.balloon:before) {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid $balloon-right-color;
    border-right: 15px solid transparent;
    border-top: 15px solid $balloon-right-color;
    border-bottom: 15px solid transparent;
    right: -16px;
    top: 0px;
  }

  .html-content :global(.balloon.left) {
    align-self: flex-start;
    background-color: $balloon-left-color;
  }

  .html-content :global(.balloon.left:before) {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid $balloon-left-color;
    border-top: 15px solid $balloon-left-color;
    border-bottom: 15px solid transparent;
    left: -16px;
    top: 0px;
  }

  .html-content :global(.balloon.wtfff) {
    background-color: $balloon-wtfff-color;
  }

  .html-content :global(.balloon.wtfff:before) {
    border-right-color: $balloon-wtfff-color;
    border-top-color: $balloon-wtfff-color;
  }

  .html-content :global(.credits p) {
    margin: 40px 0;
  }

  .html-content :global(.cols) {
    margin: rem(3) 0;
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: $page-gutter;
    width: rem(660);

    @media only screen and (max-width: 720px) {
      display: block;
      width: 100%;
      max-width: rem(660);
    }
  }

  .html-content :global(a) {
    pointer-events: initial;
    text-decoration: underline;
    color: #000;
  }

  .html-content :global(.chat-button) {
    @include solid-rounded-button("/assets/icons/arrow-top-right-pink.svg", black, $background-color);
    margin: 10px auto;
  }

  .html-content :global(.imagelinkcontainer) {
    display: flex;
  }

  .html-content :global(a.imagelink) {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    padding: 7px 60px 7px 7px;
    pointer-events: initial;
    background: transparent url(/assets/icons/arrow-top-right-black.svg) 95%
      center no-repeat;
    border: 1px solid black;
    border-radius: 74px;
    text-decoration: none;
    color: #000;
    transition: background-color 0.4s ease;
  }

  .html-content :global(a.imagelink:hover) {
    background-color: rgb(0 0 0 / 15%);
  }

  .html-content :global(a.imagelink img) {
    width: 50px;
    height: auto;
    margin-right: 10px;
  }

  .html-content :global(.credits a) {
    text-decoration: none;
  }

  .html-content :global(.credits a:hover) {
    text-decoration: underline;
  }

  .html-content :global(.credits p.privacy) {
    margin-top: 100px;
    text-align: center;
  }

  .html-content :global(.privacy a) {
    @include solid-rounded-button("/assets/icons/arrow-top-right-black.svg", $background-color, black);
    display: inline-block;
  }
</style>
