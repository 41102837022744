<script lang="ts">
  import { t } from "../utils/i18n";

  let visible = false;
</script>

<div class="simple-link">
  <p>{$t("simple.text")}</p>
  <a href={$t("simple.link")}>{$t("simple.label")}</a>
</div>

<style lang="scss">
  @import "../styles/utils.scss";

  .simple-link {
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translate(-50%, -50%);
    z-index: 99;
    @include interface-font;
    width: 50vw;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(20);
    background-color: rgba(0, 0, 0, 1);
    box-shadow: 0 0 30px rgba(0, 0, 0, 1);

    &:focus-within {
      left: 50%;
    }

    @media #{$small-screen} {
      display: none;
    }

    p {
      max-width: rem(360);
      text-align: center;
    }

    a {
      display: block;
      @include solid-rounded-button(("/assets/icons/arrow-right-black.svg"));
      padding-top: rem(12);
      padding-bottom: rem(12);
      text-decoration: none !important;
    }
  }
</style>
