<script type="ts">
  import { fly, fade } from "svelte/transition";
  import Theme from "./Theme.svelte";
  import ReplayButton from "./ReplayButton.svelte";
  import {
    activeStory, storyEnded,
    smallScreen,
  } from "../contexts/interactive-context";
  import { storiesDictionary } from "../data/stories";
  import type { Story } from "../types/story";
  import { trackVideoEvent } from "../analytics";

  let time = 0;
  let showLabel = false;
  let story: Story | null;

  $: totalTime = $activeStory
    ? story.themes[
        story.themes.length - 1
      ].end
    : 0;

  let interval = null;

  activeStory.subscribe(onActiveStoryChanged);

  function determineTime() {
    time = story.videoTag.currentTime;
    $storyEnded = story.videoTag.ended;
    trackVideoEvent(story, "progress");
    if (story.videoTag.ended) {
      showLabel = false;
    }
  }

  function onActiveStoryChanged(storyId: string | null) {
    clearInterval(interval);
    showLabel = false;
    $storyEnded = false;

    if (storyId) {
      story = storiesDictionary[storyId];
      determineTime();
      interval = setInterval(determineTime, 100);
      setTimeout(() => showLabel = true, 1000);
    }
  }
</script>

{#key $activeStory}
  {#if $activeStory}
    <!-- svelte-ignore missing-declaration -->
    <div
      class="themes"
      transition:fly={{ x: $smallScreen ? 0 : -200, y: $smallScreen ? 20 : 0, duration: $smallScreen ? 400 : 1000 }}
      class:show={$activeStory != null}
    >
      <ul>
        {#each story.themes as theme}
          <Theme {totalTime} {theme} storyId={$activeStory} {time} {showLabel}/>
        {/each}
      </ul>

      <ReplayButton show={$storyEnded}/>
    </div>
  {/if}
{/key}

<style lang="scss">
  @import "../styles/utils.scss";
  .themes {
    position: fixed;
    top: rem(110);
    left: $page-gutter;
    display: none;
    &.show {
      display: block;
    }
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      height: rem(300);
    }
    @media #{$small-screen} {
      top: auto;
      bottom: rem(6);
      left: $small-page-gutter;
      right: $small-page-gutter;
      ul {
        height: rem(50);
        flex-direction: row;
        width: 100%;
        margin-bottom: $small-page-gutter;
      }
    }
  }
</style>
