<script lang="ts">
  import { t } from "../utils/i18n";
</script>

<a href="https://verhalen.wtfff.nl" target="_blank">{$t("interface.share")}</a>

<style lang="scss">
  @import "../styles/utils.scss";

  a {
    @include solid-rounded-button("/assets/icons/arrow-top-right-black.svg");
  }
</style>
