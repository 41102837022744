<script lang="ts">
  import { audioDatabase } from "../data/asset-database";
  import { t } from "../utils/i18n";
  import { panelContent } from "../contexts/panel-context";
</script>

<button
  on:click={() => {
    audioDatabase.clickAudio.play();
    panelContent.set("/assets/panels/theme_help");
  }}
>
  {$t("interface.help")}
</button>

<style lang="scss">
  @import "../styles/utils.scss";

  button {
    @include rounded-button;
    margin-right: $small-page-gutter;
    @media #{$small-screen} {
      margin-bottom: $small-page-gutter;
      margin-right: 0;
    }
  }
</style>
