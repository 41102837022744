<script lang="ts">
  import { t } from "../utils/i18n";
  import { audioDatabase } from "../data/asset-database";
  import { trackFullscreen } from "../analytics";

  let fullscreen = false;
  let fullscreenAvailable = !!document.body.requestFullscreen;

  function toggleFullscreen() {
    if (!fullscreen) {
      trackFullscreen(true);
      document.body.requestFullscreen();
    } else {
      trackFullscreen(false);
      document.exitFullscreen();
    }

    audioDatabase.clickAudio.play();
  }

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      fullscreen = true;
    } else {
      fullscreen = false;
    }
  });
</script>

{#if fullscreenAvailable}
  <button
    class:fullscreen
    title={$t("interface.fullscreen")}
    on:click={toggleFullscreen}><slot /></button
  >
{/if}

<style lang="scss">
  @import "../styles/utils.scss";

  button {
    @include interface-font;
    pointer-events: initial;
    background: transparent url("/assets/icons/expand.svg") no-repeat;
    background-position-x: calc(100% - #{rem(8)});
    background-position-y: rem(8);
    border: none;
    padding: rem(8) rem(28) rem(8) rem(12);
    margin: 0;
    cursor: pointer;
    position: fixed;
    top: rem(16);
    right: rem(196);
    height: rem(30);
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s linear;
    &:focus-visible {
      &:after {
        content: "";
        height: 1px;
        width: rem(14);
        position: absolute;
        bottom: 4px;
        right: rem(8);
        background-color: $foreground-color;
      }
    }
    &.fullscreen {
      background-image: url("/assets/icons/compress.svg");
    }
  }
</style>
